<template>
  <div>
    <b-table fixed hover bordered small :items="items" :fields="fields">
      <template #cell(level)="row">
        {{ row.item.level }}:
        {{ row.item.name }}
      </template>
      <template #cell(color)="row">
        <div
          class="cognitive-level-color"
          :style="{
            'background-color': row.item.color,
            color: row.item.color,
          }"
        ></div>
      </template>
    </b-table>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "CognitiveColorTable",
  components: {},
  props: {
    header_bg_color: {
      type: String,
      default: "var(--kl-menu-color)",
    },
    header_text_color: {
      type: String,
      default: "var(--secondary-font-color)",
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      taxonomy: names.TAXONOMY,
    }),
    items() {
      if (!this.taxonomy) return [];
      return this.taxonomy.cognitive_levels;
    },
    fields() {
      return [
        {
          key: "level",
          label: String(
            this.$getVisibleNames(
              "mesh.cognitivelevel",
              false,
              "Nivel Cognitivo"
            )
          ),
          sortable: true,
          thClass: "th-class",
          thStyle: {
            background: `${this.header_bg_color} !important`,
            color: `${this.header_text_color}`,
          },
          tdClass: "text-left",
        },
        {
          key: "color",
          label: "Color",
          sortable: true,
          thClass: "th-class",
          thStyle: {
            background: `${this.header_bg_color} !important`,
            color: `${this.header_text_color}`,
            width: "9%",
          },
          tdClass: "text-left align-middle",
        },
      ];
    },
  },
  methods: {},
  created() {},
};
</script>

<style scoped >
.cognitive-level-color::before {
  min-width: 100px !important;
  height: 20px !important;
  opacity: 1;
  content: " #";
}
</style>

